import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { SocialButton } from '@components/SocialButton';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "socialbutton"
    }}>{`SocialButton`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={SocialButton} mdxType="Props" />
    <h2 {...{
      "id": "kind"
    }}>{`Kind`}</h2>
    <Playground __position={1} __code={'<SocialButton kind=\"facebook\" title=\"Continue with Facebook\" />\n<SocialButton kind=\"google\" title=\"Continue with Google\" />\n<SocialButton kind=\"apple\" title=\"Continue with Apple\" />\n<SocialButton kind=\"envelope\" title=\"Sign Up with Email\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      SocialButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SocialButton kind="facebook" title="Continue with Facebook" mdxType="SocialButton" />
  <SocialButton kind="google" title="Continue with Google" mdxType="SocialButton" />
  <SocialButton kind="apple" title="Continue with Apple" mdxType="SocialButton" />
  <SocialButton kind="envelope" title="Sign Up with Email" mdxType="SocialButton" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      